import React, { useContext, useMemo } from 'react';
import type { FC } from 'react';
import { DiscoveryLinksComponent } from '@src/interfaces';
import Link from "next/link";
import ComponentTitle from "@src/components/ComponentTitle";
import SvgImage from "@src/components/SvgImage";
import DiscoveryLinksContext from '@src/components/DiscoveryLinks/DiscoveryLinksContext';
import { useRouter } from 'next/router';
import stripUrlPlaceholder from '@src/utils/strip-url-placeholder';
import styles from './styles.module.scss';

const DiscoveryLinks: FC<DiscoveryLinksComponent> = ({ id, title }) => {
  const links = useContext(DiscoveryLinksContext)
  const { asPath } = useRouter()

  const fiveRandom = useMemo(() => (() => {
    const arr = links.slice()
    for (let i = arr.length - 1; i > 0; i-=1) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = arr[i];
      arr[i] = arr[j];
      arr[j] = temp;
    }
    return arr.slice(0, 5)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  })(), [links, asPath]);

  return (
    <div key={id} className={styles.container}>
      {title && <ComponentTitle title={title} />}
      <ul className={styles.items}>
        {fiveRandom.map((link, index) =>
          <li key={index}>
            <div className={styles.item}>
              <SvgImage reference='arrow-right-red' />
              <Link as={stripUrlPlaceholder(link.url)} href="/">
                <a className={styles.link}>{link.label}</a>
              </Link>
            </div>
          </li>,
        )}
      </ul>
    </div>
  );
}

export default DiscoveryLinks;
